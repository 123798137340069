<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="/" aria-current="page">Home</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a :href="downloadURL">Download</a>
          </li>
          <!-- @click="gotodownload()" -->
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="blogs">Blogs</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <div class="language-selector">
              <select id="language-select" v-model="selectedLanguage" placeholder="Select Language" @change="changeLanguage">
                <option v-for="(data, i) in lang" :key="`lang_${i}`" :value="data.lang">
                  {{ data.val }}
                </option>
              </select>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article>
            <div class="inside-article">
              <div class="entry-content" itemprop="text">
                <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                  <span aria-hidden="true" class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient" style="background: linear-gradient(180deg, rgb(2, 247, 229) 0%, rgb(0, 0, 0) 99%)" />
                  <!-- <div role="img" class="wp-block-cover__image-background wp-image-622 has-parallax" style="background-position: 50% 50%; background-image: url(wp-content/uploads/2022/12/BG-2K.webp)"></div> -->
                  <div class="wp-block-cover__inner-container">
                    <div class="wp-block-image ic sdw">
                      <figure class="aligncenter size-full">
                        <img decoding="async" width="512" height="512" src="../assets/logo.webp" alt="GBWhatsApp" class="wp-image-615">
                      </figure>
                    </div>

                    <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                      GBWhatsApp
                    </h1>

                    <div class="code-block code-block-4" style="margin: 8px auto; text-align: center; display: block; clear: both">
                      <p class="has-text-align-center v">
                        Version: <strong>18.20</strong> | Size: <strong>77MB</strong>
                      </p>
                    </div>

                    <p class="has-text-align-center d">
                      GBWhatsApp is a popular WhatsApp mod that offers users a range of enhanced features beyond the original WhatsApp. With GBWhatsApp, you can hide read receipts, stay invisible by hiding your online status, mark messages as unread, schedule messages, view deleted messages, and much more. Download GBWhatsApp today to enjoy these additional features!
                    </p>

                    <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                      <div class="wp-block-button">
                        <a class="wp-block-button__link wp-element-button" @click="downloadClick">Download GB WhatsApp
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="beesads" />

                <div id="toc_container" class="no_bullets">
                  <ul class="toc_list">
                    <li>
                      <a href="#TBC_0"><span class="toc_number toc_depth_1">1</span> GB WhatsApp App Info</a>
                    </li>
                    <li>
                      <a href="#TBC_1"><span class="toc_number toc_depth_1">2</span> Screenshot</a>
                    </li>
                    <li>
                      <a href="#TBC_2"><span class="toc_number toc_depth_1">3</span> You're banned from using GBWhatsApp</a>
                    </li>
                    <li>
                      <a href="#TBC_3"><span class="toc_number toc_depth_1">4</span> GB WhatsApp Features</a>
                    </li>
                    <li>
                      <a href="#TBC_4"><span class="toc_number toc_depth_1">5</span> Difference between GB WhatsApp and WhatsApp</a>
                    </li>
                    <li>
                      <a href="#TBC_5"><span class="toc_number toc_depth_1">6</span> Is GBWhatsApp Safe to Use?</a>
                    </li>
                    <li>
                      <a href="#TBC_6"><span class="toc_number toc_depth_1">7</span> How to Download GB WhatsApp APK for Android</a>
                    </li>
                    <li>
                      <a href="#TBC_7"><span class="toc_number toc_depth_1">8</span> Requirements to Download GB WhatsApp</a>
                    </li>
                    <li>
                      <a href="#TBC_8"><span class="toc_number toc_depth_1">9</span> How to Update GB WhatsApp</a>
                    </li>
                    <li>
                      <a href="#TBC_9"><span class="toc_number toc_depth_1">10</span> Using GB WhatsApp for PC</a>
                    </li>
                    <li>
                      <a href="#TBC_10"><span class="toc_number toc_depth_1">11</span> Using GB WhatsApp for iPhone</a>
                    </li>
                    <li>
                      <a href="#TBC_11"><span class="toc_number toc_depth_1">12</span> FAQ</a>
                    </li>
                    <li>
                      <a href="#TBC_12"><span class="toc_number toc_depth_1">13</span> Conclusion</a>
                    </li>
                  </ul>
                </div>

                <h2 class="h">
                  <span id="TBC_0">
                    GB WhatsApp App Info
                  </span>
                </h2>
                <figure class="wp-block-table is-style-regular">
                  <table class="has-fixed-layout">
                    <tbody>
                      <tr>
                        <th>App Name</th>
                        <td>GB WhatsApp</td>
                      </tr>
                      <tr>
                        <th>Version</th>
                        <td>
                          <div class="code-block code-block-3" style="margin: 8px 0; clear: both">
                            18.20
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>Rate</th>
                        <td>4.3</td>
                      </tr>
                      <tr>
                        <th>Total Downloads</th>
                        <td>2,000,000+</td>
                      </tr>
                      <tr>
                        <th>App Size</th>
                        <td>77MB</td>
                      </tr>
                      <tr>
                        <th>System</th>
                        <td>Android</td>
                      </tr>
                      <tr>
                        <th>Last Updated</th>
                        <td>2 Days Ago</td>
                      </tr>
                    </tbody>
                  </table>
                </figure>

                <h2 class="h">
                  <span id="TBC_1">
                    Screenshot
                  </span>
                </h2>
                <div class="wp-block-image">
                  <figure class="aligncenter size-full">
                    <picture>
                      <source srcset="../assets/Screenshot.webp" type="image/webp">
                      <img decoding="async" loading="lazy" width="1000" height="563" src="../assets/Screenshot.webp" alt="Screenshot" class="wp-image-48">
                    </picture>
                  </figure>
                </div>

                <h2 class="h">
                  <span id="TBC_2">
                    You're banned from using GBWhatsApp
                  </span>
                </h2>

                <p>
                  If you receive a message saying, "You are banned from using a modified version of WhatsApp" while using GB WhatsApp, it may mean giving up your current phone number and obtaining a new SIM card.
                </p>
                <p>
                  This issue has raised concerns about when GB WhatsApp will further enhance its anti-ban protection. Fortunately, the developers have been listening to user feedback from around the world and are actively working on improvements. Each new version aims to increase stability and security, reducing the risk of bans.
                </p>
                <p>
                  Now, the latest version is available—click the button to download the updated GB WhatsApp APK.
                </p>
                <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                  <div class="wp-block-button">
                    <a class="wp-block-button__link wp-element-button" @click="downloadClick()">Download GB WhatsApp</a>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_3">
                    GB WhatsApp Features
                  </span>
                </h2>
                <div class="is-layout-flex wp-container-8 wp-block-columns">
                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">
                      Ghost Mode
                    </h3>
                    <p>
                      GBWhatsApp allows users to hide their activities under ghost mode, located on the top bar for easy enabling and disabling.
                    </p>

                    <h3 class="h3">
                      DND Mode
                    </h3>
                    <p>
                      Temporarily blocks internet access for GB WhatsApp only, so you won't receive messages or notifications, allowing uninterrupted focus on other tasks.
                    </p>

                    <h3 class="h3">
                      Hide Online Status
                    </h3>
                    <p>
                      Remain invisible to contacts while using the app, so no one can see when you're online.
                    </p>

                    <h3 class="h3">
                      Hide Read Receipts
                    </h3>
                    <p>
                      View messages without notifying the sender by hiding the blue double ticks that confirm message reads.
                    </p>

                    <h3 class="h3">
                      Plenty of Themes
                    </h3>
                    <p>
                      Explore a wide range of themes to customize the app's look, including backgrounds, colors, and overall design, creating a unique interface.
                    </p>

                    <h3 class="h3">
                      Hide Typing Indicators
                    </h3>
                    <p>
                      Type freely without showing the "typing…" notification to others, enhancing privacy.
                    </p>

                    <h3 class="h3">
                      Anti-Deleted Messages
                    </h3>
                    <p>
                      Read messages even after they've been deleted by the sender; deleted messages are marked with a subtle indicator, notifying you of their removal.
                    </p>
                  </div>

                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">
                      Change Fonts
                    </h3>
                    <p>
                      Choose from a variety of font options to match your personal style.
                    </p>

                    <h3 class="h3">
                      Change Icon
                    </h3>
                    <p>
                      Personalize the GB WhatsApp icon on your home screen for a tailored aesthetic or a more discreet look.
                    </p>

                    <h3 class="h3">
                      Change Blue Ticks Icon
                    </h3>
                    <p>
                      Customize the style of the blue ticks that indicate message reads, adding a personalized touch.
                    </p>

                    <h3 class="h3">
                      Auto-Reply Messages
                    </h3>
                    <p>
                      Set automatic replies for specific messages or when you're away, ideal for business use or during busy times.
                    </p>

                    <h3 class="h3">
                      Message Scheduling
                    </h3>
                    <p>
                      Schedule messages to be sent at specific times, useful for reminders, greetings, or time-sensitive messages.
                    </p>

                    <h3 class="h3">
                      Unread Messages
                    </h3>
                    <p>
                      Track unread messages even after opening a chat, making it easier to revisit important conversations.
                    </p>

                    <h3 class="h3">
                      Lock App
                    </h3>
                    <p>
                      Protect your conversations with a passcode, fingerprint, or pattern lock, safeguarding personal messages from unauthorized access.
                    </p>

                    <h3 class="h3">
                      Go to the First Message
                    </h3>
                    <p>
                      Jump to the very beginning of any chat, perfect for reviewing past conversations without endless scrolling.
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_4">
                    Difference between GB WhatsApp and WhatsApp
                  </span>
                </h2>
                <figure class="wp-block-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Feature</th>
                        <th>GBWhatsApp</th>
                        <th>Official WhatsApp</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Ghost Mode</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>DND Mode</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Hide Blue Ticks</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Plenty Themes</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Change Fonts</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Anti-Revoked Message</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Download Statuses</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Image Sharing</td>
                        <td>Original</td>
                        <td>Compressed</td>
                      </tr>
                      <tr>
                        <td>File Sharing</td>
                        <td>2G</td>
                        <td>2G</td>
                      </tr>
                      <tr>
                        <td>License</td>
                        <td>Free</td>
                        <td>Free</td>
                      </tr>
                      <tr>
                        <td>User Base</td>
                        <td>2 million+</td>
                        <td>2 billion+</td>
                      </tr>
                      <tr>
                        <td>Root</td>
                        <td>No</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>System Support</td>
                        <td>Android</td>
                        <td>Android, iOS, Web, Desktop</td>
                      </tr>
                      <tr>
                        <td>Customer Service</td>
                        <td> <a href="https://www.reddit.com/r/GBWhatsapp/" rel="nofollow noopener" target="_blank">GB WhatsApp Group</a></td>
                        <td> <a href="https://faq.whatsapp.com/" rel="nofollow noopener" target="_blank">Official Help Center</a></td>
                      </tr>
                    </tbody>
                  </table>
                </figure>

                <h2 class="h">
                  <span id="TBC_5">
                    Is GBWhatsApp Safe to Use?
                  </span>
                </h2>
                <p>
                  <a href="/">GBWhatsApp</a>, like other modified versions of WhatsApp, provides added functionalities like custom themes, privacy options, and expanded file-sharing limits. However, since it's an unofficial third-party modification, it poses potential security and privacy risks:
                </p>
                <ul>
                  <li><strong>- Risk of Account Ban: </strong>WhatsApp's terms prohibit the use of modified apps, so using GBWhatsApp could lead to a temporary or permanent ban from WhatsApp services.</li>
                  <li><strong>- Malware Vulnerability: </strong>Since GBWhatsApp is not available on official app stores, users must download it from third-party websites. This may expose users to malware or harmful software if they download from unreliable sources.</li>
                </ul>
                <p>
                  <strong>Recommendation: </strong>If you choose to use GBWhatsApp, ensure that you download it from reputable sources, enable antivirus protection on your device, and consider using it for non-sensitive conversations to minimize potential risks.
                </p>

                <h2 class="h">
                  <span id="TBC_6"> How to Download GB WhatsApp APK for Android </span>
                </h2>
                <p>
                  Downloading GBWhatsApp on Android requires users to install the APK file manually since it's not available on the Google Play Store. Follow these steps to download GB WhatsApp safely:
                </p>
                <ul>
                  <li><strong>1. Enable Unknown Sources: </strong>Go to your Android settings, navigate to Security, and enable "Unknown Sources" to allow installation of apps outside of the Play Store.</li>
                  <li><strong>2. Find a Trusted Source: </strong>Look for a reputable site that offers the latest version of the GB WhatsApp APK.</li>
                  <li><strong>3. Download the APK File: </strong>Once you find a reliable source, download the APK file for GB WhatsApp.</li>
                  <li><strong>4. Install the APK: </strong>Open the downloaded file and follow the installation instructions to complete the setup.</li>
                  <li><strong>5. Verify Your Number: </strong>Open GB WhatsApp, enter your phone number, and verify it through an SMS code.</li>
                </ul>
                <p>
                  <i>Note: </i>Always check for updates from the same source to avoid bugs and potential security vulnerabilities.
                </p>

                <h2 class="h">
                  <span id="TBC_7">
                    Requirements to Download GB WhatsApp
                  </span>
                </h2>
                <p>
                  Before downloading and installing GBWhatsApp, make sure your device meets the following requirements:
                </p>
                <ul>
                  <li><strong>- Android Version: </strong>Android 4.0 or higher is recommended for optimal performance.</li>
                  <li><strong>- Storage Space: </strong>At least 200 MB of free space is needed to accommodate the app and its data.</li>
                  <li><strong>- Stable Internet Connection: </strong>A strong and reliable internet connection is essential for smooth installation and operation.</li>
                  <li><strong>- Backup Option: </strong>If you have an existing WhatsApp account, it's advisable to back up your chats before switching to GB WhatsApp, as direct data transfer between the two apps may not be possible.</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_8">
                    How to Update GB WhatsApp
                  </span>
                </h2>
                <p>
                  Updating GB WhatsApp is essential to access new features and maintain app stability. Since it's not in official app stores, updating requires manually downloading the latest version.
                </p>
                <ul>
                  <li><strong>1. Visit a Trusted Source: </strong>Go to the website where you initially downloaded GB WhatsApp and check for the latest version.</li>
                  <li><strong>2. Download the New APK: </strong>Download the updated APK file to your device.</li>
                  <li><strong>3. Install the Update: </strong>Open the downloaded file and proceed with installation. The update will overwrite the old version without affecting your data.</li>
                  <li><strong>4. Restart the App: </strong>After installation, open GB WhatsApp and confirm that the new features are active.</li>
                </ul>
                <p>
                  <i>Tip: </i>Set reminders to periodically check for updates on your preferred download site, as GB WhatsApp won't automatically notify you of new versions.
                </p>

                <h2 class="h">
                  <span id="TBC_9">
                    Using GB WhatsApp for PC
                  </span>
                </h2>
                <p>
                  Although there is no direct version of GB WhatsApp for PC, you can still use it on a computer through an Android emulator or by mirroring your phone's screen.
                </p>
                <h3>
                  Option 1: Using an Android Emulator
                </h3>
                <ul>
                  <li><strong>1. Download an Emulator: </strong>Install an Android emulator like Bluestacks or NoxPlayer on your PC.</li>
                  <li><strong>2. Install GB WhatsApp: </strong>Within the emulator, download and install the GB WhatsApp APK file.</li>
                  <li><strong>3. Set Up Your Account: </strong>Open GB WhatsApp in the emulator, enter your number, and verify it.</li>
                </ul>

                <h3>
                  Option 2: Phone Screen Mirroring
                </h3>
                <ul>
                  <li><strong>1. Install a Mirroring App: </strong>Download a screen-mirroring app, such as ApowerMirror or AirDroid, on both your phone and PC.</li>
                  <li><strong>2. Connect Your Devices: </strong>Link your phone to the PC using the app, and open GB WhatsApp on your phone to access it on your computer screen.</li>
                </ul>
                <p>
                  Both methods allow you to use GB WhatsApp features on a larger screen, but the emulator approach may offer a more responsive experience.
                </p>

                <h2 class="h">
                  <span id="TBC_10">
                    Using GB WhatsApp for iPhone
                  </span>
                </h2>

                <p>
                  Unfortunately, GB WhatsApp is primarily designed for Android, and there is no official iOS version. While some websites claim to offer GB WhatsApp for iPhone, these are often unreliable or potentially harmful. Installing modified apps on iOS typically requires jailbreaking, which can void your warranty and make your device vulnerable to security risks.
                </p>
                <p>
                  <strong>Alternative Solution: </strong>If you're seeking additional WhatsApp features on iOS, consider exploring WhatsApp Business, which offers more functionality than the standard app, or keep an eye out for any official feature updates from WhatsApp.
                </p>

                <h2 class="h">
                  <span id="TBC_11">
                    FAQ
                  </span>
                </h2>
                <div class="schema-faq wp-block-yoast-faq-block">
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">1. What's the difference of GB WhatsApp Pro and GB WhatsApp</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      Both versions provide similar features like privacy controls and theme options, GB WhatsApp Pro is often more optimized.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">2. Can GB WhatsApp Spy users?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      No, GB WhatsApp does not include any official spying features.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">3. Do I need to update GBWhatsApp frequently?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      Yes, it's recommended to update GB WhatsApp regularly. Frequent updates not only introduce new features and enhancements but also improve security measures, including anti-ban protection, which helps reduce the risk of being banned from WhatsApp's official service.
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_12"> Conclusion </span>
                </h2>
                <p>
                  GB WhatsApp is a popular modified app that adds exciting features beyond the official WhatsApp's scope. However, it comes with security risks and limitations, particularly in its compatibility with iOS and the potential for account bans. If you decide to use GB WhatsApp, be cautious about where you download it from, and consider using it alongside or instead of your official WhatsApp account to protect sensitive information.
                </p>
                <p>
                  Staying informed about the latest updates and using security measures on your device will help you enjoy the unique features of GB WhatsApp while minimizing risks.
                </p>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

  <div id="comment-box">
    <div class="title">
      Leave a Comment
    </div>
    <input v-model="comment.name" type="text" class="nameClass" placeholder="your name">
    <input v-model="comment.email" type="email" class="emailClass" placeholder="Email">
    <textarea v-model="comment.content" class="contentClass" />
    <button class="submitClass" @click="submitClick">
        Submit
      </button>
      <div class="commentList-box">
        <div v-for="item in showCommentList" :key="item.time" class="commentClass">
          <img src="@/assets/logo.webp" alt="">
          <div class="container">
            <div class="commentBoxClass">
              <div>
                <span class="commentName">{{ item.name }}</span>
                <span class="commentTime">{{ item.time }}</span>
              </div>
              <span class="commentContent">{{ item.content }}</span>
            </div>
            <div class="triangle" />
          </div>
        </div>
      </div>
      <button class="moreClass" @click="moreClick">
        {{ showText }}
      </button>
    </div>

    <div id="snippet-box">
      <div class="snippet-title">
        Summary
      </div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">
            Author Rating
          </div>
          <div class="snippet-data-img">
            <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span>
          </div>
          <div class="snippet-clear" />
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">
              Aggregate Rating
            </div>
            <div class="snippet-data-img">
              <span itemprop="ratingValue">3.65</span> based on
              <span class="rating-count" itemprop="ratingCount">46258</span>
              votes
            </div>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Name
          </div>
          <div class="snippet-data-img">
            <span itemprop="name">GBWhatsApp APK</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Operating System
          </div>
          <div class="snippet-data-img">
            <span itemprop="operatingSystem">Android</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Category
          </div>
          <div class="snippet-data-img">
            <span itemprop="applicationCategory">App</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Price
          </div>
          <div class="snippet-data-img">
            <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Landing Page
          </div>
          <div class="snippet-data-img">
            <a itemprop="featureList"
              href="/">https://gbapks.com.br/</a>
          </div>
          <div class="snippet-clear" />
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2022 © All Rights Reserved <strong><a href="/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="downloadClick()">
      Download GBWhatsApp New
    </div>

    <ToTop />
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
// import utlisMethods from '@/utlis/global.js';
import {
  allCommentList
} from '@/utlis/allCommentList.js';
import ToTop from '@/components/ScrollToTop.vue';
export default {
  name: 'Home',
  components: {
    ToTop,
  },
  data() {
    return {
      pageName: 'home',
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      selectedLanguage: 'en',
      lang: [{
          lang: 'en',
          val: '🇺🇸 English'
        },
        {
          lang: 'ar',
          val: '🇦🇪 اَلْعَرَبِيَّةُ'
        },
        {
          lang: 'id',
          val: '🇮🇩 Bahasa Indonesia'
        },
        {
          lang: 'po',
          val: '🇵🇹 Português'
        },
        {
          lang: 'tr',
          val: '🇹🇷 Türkçe'
        },
        {
          lang: 'in',
          val: '🇮🇳 हिंदी'
        },
      ],
    };
  },
  computed: {
    allCommentList() {
      const regularTime = Date.parse('2023/11/24 00:00:00');
      let currentTime = new Date().getTime();
      let Difference = Math.floor((currentTime - regularTime) / (3 * 24 * 60 * 60 * 1000));
      return allCommentList.slice(0, 20 + Difference).sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()) || [];
    },
    downloadURL() {
      // var params = window.location.search;
      // return `/download-gb-whatsapp${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return '/download-gb-whatsapp'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `/download-gb-whatsapp${params}`;
      }
    },
    showText() {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        return 'Click Here To Load More Comments';
      } else {
        return 'No More Comments';
      }
    },
    showCommentList() {
      return this.allCommentList.slice(0, (this.clickNum + 1) * 5) || [];
    },
  },
  mounted() {
    this.getOfficalApk();

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    moreClick() {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        this.clickNum += 1;
      }
    },
    submitClick() {
      this.$Dialog
        .confirm({
          title: 'Tip',
          message: 'Your comment has been submitted successfully and will be displayed below after review.',
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
        })
        .then(() => {
          this.comment.name = '';
          this.comment.email = '';
          this.comment.content = '';
        })
        .catch(() => {
          console.log('取消');
        });
    },
    changeLanguage(lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      this.$router.push({
        name: `${this.selectedLanguage}-gb`
      });
    },
    downloadClick() {
      this.$router.push({
        name: 'download'
      });
    },
    getOfficalApk(type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatsapp.chat',
          appName: 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload() {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/download-gb-whatsapp" + params;
      // return `/download-gb-whatsapp${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;

      // this.$global.download(this.apk);
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    jump(url) {
      window.location.href = url;
    },
  },
};
</script>
